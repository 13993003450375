import { WebPlugin } from "@capacitor/core";
import type { ConnectOptions, SendOptions, NativeWebSocketPlugin, WebSocketReadyStateResponse } from "./definitions";

export class NativeWebSocketWeb extends WebPlugin implements NativeWebSocketPlugin {

	#ws: WebSocket | null = null;

	#sendReadyStateChanged(readyState: WebSocket["readyState"]): void {
		const event = {
			readyState: readyState,
		} as const satisfies WebSocketReadyStateResponse;
		this.notifyListeners("readyStateChanged", event);
	}

	private handleWsOpen = (): void => {
		this.#sendReadyStateChanged(WebSocket.OPEN);
	};

	private handleWsClose = (event: CloseEvent): void => {
		this.notifyListeners("close", event);
	};

	private handleWsMessage = (event: MessageEvent<string>): void => {
		this.notifyListeners("message", event);
	};

	private handleWsError = (event: Event): void => {
		this.notifyListeners("error", event);
	};

	public async getReadyState(): Promise<WebSocketReadyStateResponse> {
		return Promise.resolve({ readyState: this.#ws?.readyState ?? -1 });
	}

	public async connect({ url }: ConnectOptions): Promise<void> {
		this.#sendReadyStateChanged(WebSocket.CONNECTING);

		this.#ws = new WebSocket(url);
		this.#ws.addEventListener("open", this.handleWsOpen);
		this.#ws.addEventListener("close", this.handleWsClose);
		this.#ws.addEventListener("message", this.handleWsMessage);
		this.#ws.addEventListener("error", this.handleWsError);

		return Promise.resolve();
	}

	public async disconnect(): Promise<void> {
		this.#sendReadyStateChanged(WebSocket.CLOSING);

		return new Promise((resolve) => {
			if (this.#ws) {
				this.#ws.removeEventListener("open", this.handleWsOpen);
				this.#ws.removeEventListener("close", this.handleWsClose);
				this.#ws.removeEventListener("message", this.handleWsMessage);
				this.#ws.removeEventListener("error", this.handleWsError);

				if (this.#ws.readyState === WebSocket.CLOSED) {
					this.#ws = null;
					this.#sendReadyStateChanged(WebSocket.CLOSED);
					resolve();
				} else {
					const closeHandler = (): void => {
						if (this.#ws) {
							this.#ws.removeEventListener("close", closeHandler);
							this.#ws = null;
						}
						this.#sendReadyStateChanged(WebSocket.CLOSED);
						resolve();
					};
					this.#ws.addEventListener("close", closeHandler);
					this.#ws.close(1000);
				}
			} else {
				this.#sendReadyStateChanged(WebSocket.CLOSED);
				resolve();
			}
		});
	}

	public async send({ data }: SendOptions): Promise<void> {
		if (!this.#ws) {
			throw new Error("ws not initialized");
		}
		this.#ws.send(data);
		return Promise.resolve();
	}

}

import { useState, useEffect, useCallback, useMemo, useContext, createContext } from "react";
import PropTypes from "prop-types";
import { Capacitor } from "@capacitor/core";
import { useMediaQuery } from "@mui/material";
// types
import type { ReactElement } from "react";
import type { Theme } from "@mui/material/styles";

interface NavDrawerStateContextT {
	variant: "temporary" | "persistent";
	open: boolean;
	onToggle: () => void;
	onClose: () => void;
}

export const DEFAULT_VALUE = {
	variant: "temporary",
	open: false,
	onToggle: () => {},
	onClose: () => {},
} as const satisfies NavDrawerStateContextT;

const NavDrawerStateContext = createContext<NavDrawerStateContextT>(DEFAULT_VALUE);

if (process.env.NODE_ENV !== "production") {
	NavDrawerStateContext.displayName = "NavDrawerStateContext";
}

export const useNavDrawerState = () => (useContext(NavDrawerStateContext));

type Props = {
	children: ReactElement;
};

export const NavDrawerStateProvider = ({ children }: Props) => {
	const isLargerScreen = useMediaQuery<Theme>((theme) => (theme.breakpoints.up("md")));

	const [open, setOpen] = useState(Capacitor.isNativePlatform() ? false : isLargerScreen);

	useEffect(() => {
		setOpen(Capacitor.isNativePlatform() ? false : isLargerScreen);
	}, [isLargerScreen]);

	const handleToggle = useCallback(() => {
		setOpen((prevOpen) => (!prevOpen));
	}, []);

	const handleClose = useCallback(() => {
		if (!isLargerScreen || Capacitor.isNativePlatform()) {
			setOpen(false);
		}
	}, [isLargerScreen]);

	const value = useMemo(() => ({
		variant: (!isLargerScreen || Capacitor.isNativePlatform()) ? "temporary" : "persistent",
		open: open,
		onToggle: handleToggle,
		onClose: handleClose,
	} as const), [isLargerScreen, open, handleToggle, handleClose]);

	return (
		<NavDrawerStateContext.Provider value={value}>
			{children}
		</NavDrawerStateContext.Provider>
	);
};

NavDrawerStateProvider.propTypes = {
	children: PropTypes.element.isRequired,
};

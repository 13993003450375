import { Capacitor } from "@capacitor/core";
import useIsSmallScreen from "./useIsSmallScreen";
import useHasTouchscreen from "./useHasTouchscreen";

const useShouldAutofocus = () => {
	const isSmallScreen = useIsSmallScreen();
	const hasTouchscreen = useHasTouchscreen();

	return !Capacitor.isNativePlatform() && !isSmallScreen && !hasTouchscreen;
};

export default useShouldAutofocus;

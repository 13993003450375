import { useState, useEffect } from "react";
// types
import type { Days, Day } from "../types/misc";

// TODO: remove WeekInfo and LocaleWithWeekInfo type if default supported
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getWeekInfo
interface WeekInfo {
	firstDay: Day;
	weekend: Readonly<Days>;
	minimalDays: Day;
}
export interface LocaleWithWeekInfo extends Intl.Locale {
	getWeekInfo: () => WeekInfo;
	/** @deprecated Only use as fallback for `getWeekInfo()` */
	weekInfo?: WeekInfo;
}

const DEFAULT_WEEKINFO = {
	firstDay: 1,
	weekend: [6, 7],
	minimalDays: 4,
} as const satisfies WeekInfo;

const getLocale = () => {
	const locale = new Intl.Locale(new Intl.NumberFormat().resolvedOptions().locale) as LocaleWithWeekInfo;
	locale.getWeekInfo ??= () => (DEFAULT_WEEKINFO as WeekInfo); // eslint-disable-line @typescript-eslint/no-unnecessary-condition
	return locale;
};

/**
 * Do NOT destruct `locale`. Do: `const locale = useLocale();`
 */
const useLocale = (): LocaleWithWeekInfo => {
	const [locale, setLocale] = useState(getLocale);

	useEffect(() => {
		const handleLanguageChange = () => (setLocale(getLocale()));

		window.addEventListener("languagechange", handleLanguageChange);

		return () => {
			window.removeEventListener("languagechange", handleLanguageChange);
		};
	}, []);

	return locale;
};

export default useLocale;

import { useState, useEffect } from "react";
// contexts
import { useGateway } from "../context/GatewayContext";
// services
import Constants from "../services/constants";
import Rules from "../services/rules";
// types
import type { DeviceId, EndpointId } from "../types/device";
import type { RuleType, Rules as RulesT } from "../types/rule";

const RULE_TYPES_ALL = [Constants.Rule.Type.Template, Constants.Rule.Type.Scheduler, Constants.Rule.Type.Advanced] as const;

export const filterRules = <RTS extends Readonly<Array<RuleType>>>(rules: Readonly<RulesT>, ruleTypes: RTS, deviceId: DeviceId | undefined, endpointId: EndpointId | undefined) => (
	rules.filter((rule) => {
		if (!ruleTypes.includes(rule.type)) {
			return false;
		}
		if (deviceId) {
			const checksAvailable = rule.checks.some((check) => (check.deviceId === deviceId && (endpointId === undefined || check.endpoint === endpointId)));
			const actionsAvailable = rule.actions.some((action) => (action.deviceId === deviceId && (endpointId === undefined || action.endpoint === endpointId)));
			return checksAvailable || actionsAvailable;
		}
		return true;
	}) as unknown as Readonly<RulesT<RTS[number]>>
);

/**
 * @see https://stackoverflow.com/questions/56505560/how-to-fix-ts2322-could-be-instantiated-with-a-different-subtype-of-constraint/59363875#59363875
 *
 * @param ruleTypes DON'T DO `useRules([XXX])`! Instead define array outside the component or use `useMemo()`
 * @param deviceId
 * @param endpointId
 */
const useRules = <RTS extends Readonly<Array<RuleType>>>(ruleTypes: RTS = RULE_TYPES_ALL, deviceId?: DeviceId, endpointId?: EndpointId) => {
	const gateway = useGateway();

	const [loaded, setLoaded] = useState(Rules.isLoaded());
	const [loading, setLoading] = useState(!Rules.isLoaded());
	const [rules, setRules] = useState<Readonly<RulesT<RTS[number]>>>(filterRules(Rules.get(), ruleTypes, deviceId, endpointId));

	useEffect(() => {
		const handleRulesReset = () => {
			setLoading(!Rules.isLoaded());
			setRules(filterRules(Rules.get(), ruleTypes, deviceId, endpointId));
		};

		const handleRulesChanged = (_rules: Readonly<RulesT>/*, ruleIds: Array<RuleId>, shouldUpdateRule: boolean*/) => {
			setRules(filterRules(_rules, ruleTypes, deviceId, endpointId));
		};

		Rules.on("reset", handleRulesReset);
		Rules.on("rulesChanged", handleRulesChanged);

		return () => {
			Rules.off("reset", handleRulesReset);
			Rules.off("rulesChanged", handleRulesChanged);
		};
	}, [ruleTypes, deviceId, endpointId]);

	useEffect(() => {
		if (!Rules.isLoaded() && gateway && gateway.status !== Constants.Gateway.Status.Unreachable) {
			setLoading(true);

			Rules.getRules((error, _rules) => {
				if (!error && _rules) {
					setRules(filterRules(_rules, ruleTypes, deviceId, endpointId));
				}
				setLoaded(true);
				setLoading(false);
			});
		}
	}, [gateway?.id, gateway?.status, ruleTypes, deviceId, endpointId]);

	return {
		loaded: loaded,
		loading: loading,
		rules: rules,
	} as const;
};

export default useRules;

import { useState, useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
// services
import { getPhoneUUIDFromStorage, setPhoneUUIDToStorage } from "../services/phoneuuid-helper";
// types
import type { DeviceId } from "../types/device";

export const getDeviceId = async (): Promise<DeviceId> => {
	if (Capacitor.isNativePlatform()) {
		const migratedPhoneUUID = await getPhoneUUIDFromStorage();
		if (migratedPhoneUUID) {
			return migratedPhoneUUID.replaceAll(/[^\dA-F]/g, "") as DeviceId;
		}
	}
	const { identifier } = await Device.getId();
	let transformedDeviceId = identifier.toUpperCase().replaceAll(/[^\dA-F]/g, "");
	if (transformedDeviceId.length % 2 === 1) { // only values with an even count of chars are allowed
		transformedDeviceId += "0";
	}

	if (Capacitor.isNativePlatform()) {
		await setPhoneUUIDToStorage(transformedDeviceId);
	}

	return transformedDeviceId as DeviceId;
};

const useDeviceInfo = () => {
	const [loading, setLoading] = useState(true);
	const [deviceId, setDeviceId] = useState<DeviceId>("" as DeviceId);
	const [deviceName, setDeviceName] = useState("");
	const [deviceOsVersion, setDeviceOsVersion] = useState("");

	useEffect(() => {
		const tasks = [getDeviceId(), Device.getInfo()] as const;
		Promise.all(tasks).then(([transformedDeviceId, { name, model, osVersion }]) => {
			setDeviceId(transformedDeviceId);
			setDeviceName(name ?? model);
			setDeviceOsVersion(osVersion);
		}).catch((error) => {
			console.warn(error);
		}).finally(() => {
			setLoading(false);
		});
	}, []);

	return {
		loading: loading,
		deviceId: deviceId,
		deviceName: deviceName,
		deviceOsVersion: deviceOsVersion,
	} as const;
};

export default useDeviceInfo;

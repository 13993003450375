import { useCallback } from "react";
// hooks
import useLocale from "./useLocale";

const useNumberFormatter = (fractionDigits: number): Intl.NumberFormat["format"] => {
	const { language } = useLocale();

	const format = useCallback(new Intl.NumberFormat(language, { minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }).format, [language, fractionDigits]);

	return format;
};

export default useNumberFormatter;

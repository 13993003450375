//replacement for capacacitor - preferences using the same methods
//is used to write in specific named userDefaults, e.g. for AppGroups to share between Extensions
//old: setting group for iOS just added {group}.{key} instead of writing in a different Suite

/*
is needed for Migration as we use ROCApp as group there
Capacitor.Preferences is misusing the group, so we can't get the values from it:
	public init(for group: Group = .named("CapacitorStorage")) {
		self.group = group
	}
	private var defaults: UserDefaults {
			return UserDefaults.standard
	}
	private var prefix: String {
			switch configuration.group {
			case .cordovaNativeStorage:
					return ""
			case let .named(group):
					return group + "."
			}
	}
	get: prefix + key
*/

import { registerPlugin } from "@capacitor/core";

import type { CustomUserDefaultsPlugin } from "./definitions";

const CustomUserDefaults = registerPlugin<CustomUserDefaultsPlugin>("CustomUserDefaults");

export default CustomUserDefaults;

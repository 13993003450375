import useMediaQuery from "@mui/material/useMediaQuery";

const useHasTouchscreen = () => {
	const matches = useMediaQuery("(any-pointer: coarse)");

	return (window.PointerEvent && ("maxTouchPoints" in navigator)) // eslint-disable-line @typescript-eslint/no-unnecessary-condition
		? navigator.maxTouchPoints > 0 // if Pointer Events are supported, just check maxTouchPoints
		: matches; // check for any-pointer:coarse which mostly means touchscreen
};

export default useHasTouchscreen;
